<script setup>
import { ModalsContainer } from "vue-final-modal";
import UserPurchaseModal from "./components/UserPurchaseModal.vue";
import { useModal } from "vue-final-modal";
import NavBar from "@/components/Navbar";
import Sidebar from "@/components/Sidebar";
import navigation from "./navigation";
import { mapGetters } from "vuex";
import UIkit from "uikit";
import { reactive, computed, watch } from "vue";
import { useStore } from "vuex";
import "vue-final-modal/style.css";

const $store = useStore();

const isAuthenticated = computed(() => $store.getters.isAuthenticated);
const loaderOn = computed(() => $store.getters.getLoaderOn);
const getnotificationMessages = computed(
	() => $store.getters.getNotificationMessages
);
watch(
	() => $store.getters.getNotificationMessages,
	(messages, oldValue) => {
		if (messages.length === 0) return;
		messages.forEach((m) => UIkit.notification(m.message, m.status));
		return $store.dispatch("removeNotificationMessage");
	},
	{ deep: true }
);
</script>

<template>
	<ModalsContainer />

	<div class="uk-height-viewport">
		<template v-if="!$route.meta.public">
			<template v-if="isAuthenticated">
				<nav-bar />
			</template>

			<div class="uk-container uk-container-expand">
				<div uk-grid margin>
					<div class="uk-width-auto">
						<sidebar></sidebar>
					</div>
					<div
						class="primary-container uk-width-expand"
						:class="[loaderOn ? 'loader-on' : '']"
					>
						
						<router-view />
						<div
							v-if="loaderOn"
							class="loader"
							:key="loaderOn ? 'spinnerOn' : 'spinnerOff'"
						>
							<div uk-spinner="ratio: 1.5"></div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="uk-container uk-height-viewport">
				<router-view />
				<div v-if="loaderOn" class="loader">
					<div uk-spinner="ratio: 1.5"></div>
				</div>
			</div>
		</template>
	</div>
</template>

<style lang="scss">
html {
	height: 100%;
}
body {
	background: #eee;
	font-family: "Source Sans Pro";
	font-size: 12pt;
	line-height: 1.375em;
	font-weight: 300;
	min-height: 100%;
	color: #000;
	-webkit-touch-callout: none;
}

:focus {
	outline: 0;
}

::placeholder {
	color: #999;
	opacity: 1;
}
:-ms-input-placeholder {
	color: #999;
}
::-ms-input-placeholder {
	color: #999;
}

* {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Source Sans Pro" !important;
	font-weight: 300 !important;
	color: #00549e !important;
}
h5,
h6 {
	font-weight: 400 !important;
	color: #000 !important;
}

a {
	color: #00549e;
	text-decoration: none;
}

b,
strong {
	font-weight: 700;
}

input:required {
	box-shadow: none;
}

#header {
	color: #fff;
	background: #00549e;
}
#header a {
	color: inherit;
}
#header .logo {
	font-size: 24px;
	line-height: 30px;
	font-weight: 300;
	padding: 0 25px 0 0;
}
#header .logo img {
	width: 172px;
	height: auto;
}

#header .actions {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: center;
	align-items: center;

	border-left: 1px solid rgba(255, 255, 255, 0.25);
	padding-left: 10px;
	color: #fff;
	font-size: 12pt;
}

#header .actions ul {
	list-style: none;
	margin: 0 10px 0 0;
	padding: 0;
	display: flex;
	align-content: center;
	align-items: center;
}
#header .actions ul li {
	font-weight: 400;
}

#header .uk-navbar-item {
	height: 70px;
	color: #fff;
	font-family: "Source Sans Pro";
	font-size: 12pt;
}
#header button {
	color: #fff;
	font-family: "Source Sans Pro";
	font-size: 12pt;
	text-transform: none;
}

.uk-table-divider > :not(:first-child) > tr,
.uk-table-divider > :first-child > tr:not(:first-child) {
	border-top: 1px solid #d3d3d3 !important;
}

hr {
	border-color: #d3d3d3 !important;
}

.td-actions {
	min-width: 75px;
}

input,
select,
textarea {
	color: #00549e !important;
	font-weight: normal !important;
}
::placeholder {
	font-weight: 300 !important;
	color: #999 !important;
}
button {
	font-weight: normal !important;
	font-size: 12pt;
}
.loader {
	position: fixed;
	top: 45%;
	left: 50%;
	transform: translateY(-25%);
}
.primary-container {
	position: relative;
}
.loader-on {
	opacity: 0.5;
}
</style>

<style lang="scss">
@import "../node_modules/uikit/src/scss/variables.scss";
@import "../node_modules/uikit/src/scss/mixins.scss";
@import "../node_modules/uikit/src/scss/uikit.scss";
</style>